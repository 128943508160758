// Archivo: src/api/LandingApi.js

/**
 * Envía los datos del formulario al backend.
 * @param {Object} formData - Datos del formulario a enviar.
 * @returns {Promise<string>} - Respuesta del servidor.
 * @throws {Error} - Si ocurre un error en la solicitud.
 */
export const submitLandingForm = async (formData) => {
  try {
      // Enviar los datos a la base de datos
      const response = await fetch('/procesar.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      });

      if (!response.ok) {
          throw new Error('Error en la solicitud al servidor');
      }

      const dbResult = await response.text();

      // Enviar los datos también por correo (en segundo plano)
      fetch('/enviar-correo.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      }).catch(error => console.error('Error al enviar el correo:', error));

      return dbResult; // Mantiene el retorno original (respuesta del servidor)
  } catch (error) {
      console.error('Error al enviar el formulario:', error);
      throw error;
  }
};
