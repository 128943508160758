import React from 'react';
import HomeLineaSliderDesktop from './HomeLineaSliderDesktop';
import HomeSerieSliderDesktop from './HomeSerieSliderDesktop';
import './HomeShowcaseDesktop.css';

function HomeShowcaseDesktop({ lineas, currentLinea, currentLineaSeries, handleLineaChange, lineaSliderRef, serieSliderRef, loading }) {
  const selectedLinea = lineas.find(linea => linea.id === currentLinea);

  return (
    <div className="home-showcase-desktop-container">
      
      <div className="home-showcase-desktop-lineas-wrapper">
        <HomeLineaSliderDesktop 
          lineas={lineas} 
          onLineaChange={handleLineaChange} 
          sliderRef={lineaSliderRef} 
          loading={loading} 
        />
      </div>

      <div className="home-showcase-desktop-series-wrapper">
        <HomeSerieSliderDesktop 
          series={currentLineaSeries} 
          sliderRef={serieSliderRef} 
          loading={loading} 
          linea={selectedLinea}  // Pasamos la linea seleccionada como prop
        />
      </div>

      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseDesktop;
