import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductDataProvider from '../components/Productos/ProductDataProvider';
import ProductListWrapper from '../components/Productos/ProductListWrapper';
import ProductGrid from '../components/Productos/ProductGrid';
import PageBanner from '../components/PageBanner';
import PageFooter from '../components/PageFooter';
import './Productos.css';

function Productos() {
  const location = useLocation();
  
  // Obtener selecciones desde el estado de navegación
  const navState = location.state || {};
  const initialSelectedLinea = navState.selectedLineaId;
  const initialSelectedSerie = navState.selectedSerie;

  useEffect(() => {
    document.title = 'Zoomlion Latinoamérica | Productos';
    const metaDescription = document.querySelector('meta[name="description"]') || 
      document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Catálogo completo de maquinaria Zoomlion';
    document.head.appendChild(metaDescription);
  }, []);

  return (
    <div className="products-page-container">
      <div className="products-page-banner">
        <PageBanner />
      </div>
      
      <ProductDataProvider
        initialSelectedLinea={initialSelectedLinea}
        initialSelectedSerie={initialSelectedSerie}
      >
        {({ 
          lineas, 
          selectedSerie, 
          setSelectedSerie, 
          selectedLinea, 
          setSelectedLinea, 
          modelos, 
          loadingModelos, 
          error 
        }) => {
          const selectedLineaObj = lineas.find(l => l.documentId === selectedLinea);
          return (
            <div className="products-page-content">
              <ProductListWrapper
                lineasProp={lineas}
                selectedSerie={selectedSerie}
                setSelectedSerie={setSelectedSerie}
                setSelectedLinea={setSelectedLinea}
              />
              <ProductGrid
                modelos={modelos}
                loading={loadingModelos}
                error={error}
                selectedSerieName={selectedSerie?.nombre}
                selectedLineaName={selectedLineaObj?.nombre}
              />
            </div>
          );
        }}
      </ProductDataProvider>
      
      <div className="products-page-footer">
        <PageFooter />
      </div>
    </div>
  );
}

export default Productos;