import React, { useState, useEffect } from 'react';
import ProductListDesktop from './ProductListDesktop';
import ProductListMobile from './ProductListMobile';

function ProductListWrapper({ 
  lineasProp, 
  selectedSerie, 
  setSelectedSerie,
  setSelectedLinea 
}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!lineasProp.length) return <div>Cargando líneas y series...</div>;

  return isMobile ? (
    <ProductListMobile
      lineas={lineasProp}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
      setSelectedLinea={setSelectedLinea}
    />
  ) : (
    <ProductListDesktop
      lineas={lineasProp}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
      setSelectedLinea={setSelectedLinea}
    />
  );
}

export default ProductListWrapper;