import React from 'react';
import ModelCard from './ModelCard';
import './ProductGrid.css';

function ProductGrid({ modelos, loading, error, selectedSerieName, selectedLineaName }) {
  return (
    <div className="product-grid-container">
      {selectedSerieName && (
        <h2 className="product-grid-breadcrumb">Modelos de la serie: {selectedSerieName}</h2>
      )}
      {selectedLineaName && !selectedSerieName && (
        <h2 className="product-grid-breadcrumb">Modelos de la línea: {selectedLineaName}</h2>
      )}
      
      <div className="product-grid">
        {!selectedSerieName && !selectedLineaName ? (
          <div className="product-grid-message">Selecciona una línea o serie</div>
        ) : loading ? (
          <div className="product-grid-message">Cargando modelos...</div>
        ) : error ? (
          <div className="product-grid-message">Error: {error}</div>
        ) : modelos.length === 0 ? (
          <div className="product-grid-message">No hay modelos disponibles</div>
        ) : (
          modelos.map((modelo) => (
            <ModelCard
              key={modelo.documentId}
              nombre={modelo.nombre}
              especificaciones={modelo.especificaciones}
              imagen={modelo.imagen_card || '/images/default-banner.webp'}
              slug={modelo.slug}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ProductGrid;