import React, { useState, useEffect, useRef } from 'react';
import PageFooter from '../components/PageFooter';
import LandingForm from '../components/Landing/LandingForm';
import './Landing.css';
import { submitLandingForm } from '../api/LandingApi';

const Landing = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    city: '',
    company: '',
    ruc: '',
    machines: '',
  });
  const formRef = useRef(null);

  // Cambiar la imagen del carrusel automáticamente
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4); // 4 imágenes en total
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  // Hacer scroll hacia el formulario
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Enviar los datos del formulario usando LandingApi
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseMessage = await submitLandingForm(formData); // Llamada a la API
      alert(responseMessage); // Mostrar respuesta del backend
    } catch (error) {
      alert('Hubo un error al enviar el formulario. Por favor, inténtalo nuevamente.');
    }
  };

  // Lista de imágenes para el carrusel (se queda en la página)
  const imageList = [
    '/images/LandingPeru/grua.png',
    '/images/LandingPeru/agricultura.png',
    '/images/LandingPeru/mineria.png',
    '/images/LandingPeru/construccion.png',
  ];

  return (
    <div className="landing-peru-page">
      {/* Hero Section */}
      <div className="landing-peru-hero">
        <div className="landing-peru-text-section">
          <h1 className="landing-peru-title">
            Maquinaria de alta calidad para minería, construcción y agro
          </h1>
          <p className="landing-peru-subtitle">
            Nueve líneas de equipos combinando innovación, tecnología inteligente y soluciones sostenibles.
          </p>
          <button className="landing-peru-contact-button" onClick={scrollToForm}>
            COTIZAR
          </button>
        </div>
        <div className="landing-peru-image-section">
          <img
            key={currentImageIndex}
            src={imageList[currentImageIndex]}
            alt="Service"
            className="landing-peru-page-img slide-image"
          />
        </div>
      </div>

      {/* Form Section */}
      <div ref={formRef} className="landing-peru-form-section">
        <h2 className="landing-peru-form-title">Pongámonos en contacto</h2>
        <div className="landing-peru-form-content">
          <div className="landing-peru-form-image">
            <img src="/images/LandingPeru/contacto.png" alt="Contacto" />
          </div>
          <form className="landing-peru-form" onSubmit={handleSubmit}>
            <div className="landing-peru-form-group">
              <label htmlFor="name">Nombre:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Tu nombre"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="phone">Celular:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Tu celular"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="email">Correo:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Tu correo"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="city">Ciudad:</label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Tu ciudad"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="company">Empresa:</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Tu empresa"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="ruc">RUC:</label>
              <input
                type="text"
                id="ruc"
                name="ruc"
                placeholder="Tu RUC"
                value={formData.ruc}
                onChange={handleChange}
                required
              />
            </div>
            <div className="landing-peru-form-group">
              <label htmlFor="machines">Máquina(s) de interés:</label>
              <textarea
                id="machines"
                name="machines"
                placeholder="Escribe aquí..."
                rows="3"
                value={formData.machines}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="landing-peru-form-submit-button">Enviar</button>
          </form>
        </div>
      </div>

      {/* Footer */}
      <div className="landing-peru-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Landing;
