import React, { useState, useEffect } from "react";
import "./Leads.css";

function Leads() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [error, setError] = useState("");

  // Manejar el inicio de sesión enviando credenciales al backend
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/auth.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (data.success) {
        setIsAuthenticated(true);
        setError("");
      } else {
        setError(data.error || "Credenciales inválidas. Inténtalo de nuevo.");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Hubo un problema al conectarse al servidor.");
    }
  };

  // Obtener los leads si el usuario está autenticado
  useEffect(() => {
    if (isAuthenticated) {
      fetch("/consumir.php")
        .then((response) => response.json())
        .then((data) => setLeads(data))
        .catch((err) => console.error("Error:", err));
    }
  }, [isAuthenticated]);

  const markAsAttended = (id) => {
    fetch("/consumir.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al actualizar el estado.");
        }
        return response.json();
      })
      .then(() => {
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.id === id ? { ...lead, status: "atendido" } : lead
          )
        );
      })
      .catch((err) => console.error(err));
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "Fecha no disponible";
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const openModal = (lead) => {
    setSelectedLead(lead);
  };

  const closeModal = () => {
    setSelectedLead(null);
  };

  if (!isAuthenticated) {
    return (
      <div className="leads-page-login-container">
        <form onSubmit={handleLogin} className="leads-page-login-form">
          <h2 className="leads-page-login-title">Iniciar sesión</h2>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="leads-page-login-input"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="leads-page-login-input"
          />
          {error && <p className="leads-page-error">{error}</p>}
          <button type="submit" className="leads-page-login-button">
            Ingresar
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="leads-page-container">
      <h1 className="leads-page-title">Lista de Leads</h1>
      <table className="leads-page-table">
        <thead>
          <tr>
            {["Nombre", "Teléfono", "Correo", "Estado", "Creado el", "Acción", "Ver más"].map(
              (header) => (
                <th key={header} className="leads-page-table-header">
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td className="leads-page-table-cell">{lead.name}</td>
              <td className="leads-page-table-cell">{lead.phone}</td>
              <td className="leads-page-table-cell">{lead.email}</td>
              <td className="leads-page-table-cell">{lead.status}</td>
              <td className="leads-page-table-cell">
                {formatDate(lead.created_at)}
              </td>
              <td className="leads-page-table-cell">
                {lead.status === "nuevo" ? (
                  <button
                    onClick={() => markAsAttended(lead.id)}
                    className="leads-page-button"
                  >
                    Marcar como atendido
                  </button>
                ) : (
                  "Atendido"
                )}
              </td>
              <td className="leads-page-table-cell">
                <button
                  onClick={() => openModal(lead)}
                  className="leads-page-button-view-more"
                >
                  Ver más
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedLead && (
        <div className="leads-page-modal">
          <div className="leads-page-modal-content">
            <h2>Detalles de {selectedLead.name}</h2>
            {Object.entries(selectedLead).map(([key, value]) => (
              <p key={key}>
                <strong>{key}: </strong>
                {value}
              </p>
            ))}
            <button className="leads-page-button" onClick={closeModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Leads;
