import React, { useState } from 'react';
import './ProductListMobile.css';

function ProductListMobile({ 
  lineas, 
  selectedSerie, 
  setSelectedSerie,
  setSelectedLinea
}) {
  // Estado local para la línea, similar a "expandedLineaId" del Desktop
  const [selectedLineaLocal, setSelectedLineaLocal] = useState(null);

  const sortedLineas = (lineas || []).sort(
    (a, b) => (a.posicion || 0) - (b.posicion || 0)
  );

  // Al cambiar la línea en el <select>
  const handleLineaChange = (event) => {
    const lineaDocumentId = event.target.value || null;
    // Guardamos localmente
    setSelectedLineaLocal(lineaDocumentId);
    // Avisamos al padre
    setSelectedLinea(lineaDocumentId);
    // Resetear serie
    setSelectedSerie(null);
  };

  // Al cambiar la serie en el <select>
  const handleSerieChange = (event) => {
    const serieDocumentId = event.target.value || null;

    // Buscamos la línea local seleccionada
    const selectedLineaObj = lineas.find(
      (linea) => linea.documentId === selectedLineaLocal
    );
    // Sacamos la serie
    const serie = selectedLineaObj?.series?.find(
      (s) => s.documentId === serieDocumentId
    );

    // Actualizamos la serie en el padre
    setSelectedSerie(serie || null);
    
    // Si quieres replicar EXACTAMENTE el comportamiento del Desktop,
    // donde al seleccionar serie se "oculta" la línea, harías:
    // setSelectedLineaLocal(null);
    // setSelectedLinea(null);
    // Pero eso *escondería* el segundo <select>, así que, si quieres
    // mantenerlo visible, NO lo hagas.
  };

  // Botón RESTABLECER
  const handleReset = () => {
    // Reset local
    setSelectedLineaLocal(null);
    // Reset en el padre
    setSelectedLinea(null);
    setSelectedSerie(null);
  };

  return (
    <div className="product-list-mobile-container">
      <h2 className="product-list-title">Buscar</h2>

      {/* Selector de Línea (controlado localmente con selectedLineaLocal) */}
      <div className="product-list-mobile-select-container">
        <label htmlFor="linea-select">Línea:</label>
        <select
          id="linea-select"
          value={selectedLineaLocal || ''}
          onChange={handleLineaChange}
          className="product-list-mobile-select"
        >
          <option value="">Selecciona una línea</option>
          {sortedLineas.map((linea) => (
            <option key={linea.documentId} value={linea.documentId}>
              {linea.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Selector de Serie (solo se muestra si tenemos una línea local seleccionada) */}
      {selectedLineaLocal && (
        <div className="product-list-mobile-select-container">
          <label htmlFor="serie-select">Serie:</label>
          <select
            id="serie-select"
            value={selectedSerie?.documentId || ''}
            onChange={handleSerieChange}
            className="product-list-mobile-select"
          >
            <option value="">Selecciona una serie</option>
            {lineas
              .find((l) => l.documentId === selectedLineaLocal)
              ?.series?.sort((a, b) => (a.posicion || 0) - (b.posicion || 0))
              .map((serie) => (
                <option key={serie.documentId} value={serie.documentId}>
                  {serie.nombre}
                </option>
              ))}
          </select>
        </div>
      )}

      <button className="product-list-mobile-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductListMobile;
