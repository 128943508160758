import React, { useState } from 'react';
import './ProductListDesktop.css';

function ProductListDesktop({ 
  lineas, 
  selectedSerie, 
  setSelectedSerie, 
  setSelectedLinea 
}) {
  const [expandedLineaId, setExpandedLineaId] = useState(null);

  const handleLineaClick = (lineaId) => {
    const newExpanded = expandedLineaId === lineaId ? null : lineaId;
    setExpandedLineaId(newExpanded);
    setSelectedLinea(newExpanded);
    setSelectedSerie(null); // Resetear serie al cambiar línea
  };

  const handleReset = () => {
    setExpandedLineaId(null);
    setSelectedLinea(null);
    setSelectedSerie(null);
  };

  return (
    <div className="product-list-desktop-container">
      <ul className="product-list-desktop-list">
        <h2 className="product-list-desktop-title">BUSCAR</h2>
        {(lineas || []).sort((a, b) => a.posicion - b.posicion).map((linea) => (
          <li key={linea.documentId}>
            <div
              className={`product-list-desktop-linea-header ${
                expandedLineaId === linea.documentId ? 'selected-linea' : ''
              }`}
              onClick={() => handleLineaClick(linea.documentId)}
            >
              <p>{linea.nombre}</p>
              <span className="material-icons">expand_more</span>
            </div>
            
            {expandedLineaId === linea.documentId && (
              <ul className="product-list-desktop-series-list">
                {(linea.series || []).sort((a, b) => a.posicion - b.posicion).map((serie) => (
                  <li
                    key={serie.documentId}
                    onClick={() => {
                      setSelectedSerie(serie);
                      setSelectedLinea(null); // Resetear línea al seleccionar serie
                    }}
                    className={
                      selectedSerie?.documentId === serie.documentId 
                        ? 'selected-serie' 
                        : ''
                    }
                  >
                    {serie.nombre}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <button className="product-list-desktop-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductListDesktop;